<template>
  <v-form
    class="multi-col-validation"
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="formData.map"
          :items="mapOptions"
          item-text="label"
          item-value="value"
          :rules="[(v) => !!v || 'Mohon pilih tipe akun']"
          label="Tipe Akun"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.fullName"
          :rules="[(v) => !!v || 'Mohon isi nama lengkap']"
          label="Nama Lengkap"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.productCode"
          :rules="[(v) => !!v || 'Mohon isi provider']"
          label="Kode Provider"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.phoneNumber"
          :rules="[(v) => !!v || 'Mohon isi nomor telepon']"
          type="tel"
          label="Nomor Telepon"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.email"
          :rules="[(v) => !!v || 'Mohon isi email']"
          label="Email"
          type="email"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.idNumber"
          :rules="[(v) => !!v || 'Mohon isi nomor ID']"
          label="Nomor ID"
          type="number"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="birthDateMenu"
          v-model="birthDateMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.birthDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.birthDate"
              label="Tanggal Lahir"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.birthDate"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="birthDateMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.birthDateMenu.save(formData.birthDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.officeName"
          :rules="[(v) => !!v || 'Mohon isi nama kantor']"
          label="Nama Kantor"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="startDateMenu"
          v-model="startDateMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.startDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.startDate"
              label="Tanggal Mulai"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.startDate"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="startDateMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.startDateMenu.save(formData.startDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.address"
          :rules="[(v) => !!v || 'Mohon isi alamat']"
          label="Alamat"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.village"
          :rules="[(v) => !!v || 'Mohon isi desa']"
          label="Desa"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.subdistrict"
          :rules="[(v) => !!v || 'Mohon isi kecamatan']"
          label="Kecamatan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.city"
          :rules="[(v) => !!v || 'Mohon isi kabupaten']"
          label="Kabupaten"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.province"
          :rules="[(v) => !!v || 'Mohon isi provinsi']"
          label="Provinsi"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.postalCode"
          :rules="[(v) => !!v || 'Mohon isi kode pos']"
          label="Kode Pos"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.plafons"
          :rules="[(v) => !!v || 'Mohon isi plafon']"
          label="Plafon"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.job"
          :rules="[(v) => !!v || 'Mohon isi pekerjaan']"
          label="Pekerjaan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.jobType"
          :rules="[(v) => !!v || 'Mohon isi tipe pekerjaan']"
          label="Tipe Pekerjaan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.jobOnline"
          :rules="[(v) => !!v || 'Mohon isi pekerjaan online']"
          label="Pekerjaan Online"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.income"
          :rules="[(v) => !!v || 'Mohon isi pemasukan']"
          label="Pemasukan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.asset"
          :rules="[(v) => !!v || 'Mohon isi aset']"
          label="Aset"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.homeOwnership"
          :rules="[(v) => !!v || 'Mohon isi kepemilikan rumah']"
          label="Kepemilikan Rumah"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.religion"
          :rules="[(v) => !!v || 'Mohon isi agama']"
          label="Agama"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.maritalStatus"
          :rules="[(v) => !!v || 'Mohon isi status perkawinan']"
          label="Status Perkawinan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.education"
          :rules="[(v) => !!v || 'Mohon isi pendidikan']"
          label="Pendidikan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.motherMaidenName"
          :rules="[(v) => !!v || 'Mohon isi nama gadis ibu']"
          label="Nama Gadis Ibu"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.emergencyContactNumber"
          :rules="[(v) => !!v || 'Mohon isi nomor telepon darurat']"
          label="Nomor Telepon Darurat"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.emergencyContactName"
          :rules="[(v) => !!v || 'Mohon isi nama kontak darurat']"
          label="Nama Kontak Darurat"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.emergencyContactRelationship"
          :rules="[(v) => !!v || 'Mohon isi hubungan dengan kontak darurat']"
          label="Hubungan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.bankName"
          :rules="[(v) => !!v || 'Mohon isi nama bank']"
          label="Nama Bank"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.bankAccountNumber"
          :rules="[(v) => !!v || 'Mohon isi nomor rekening bank']"
          label="Nomor Rekening Bank"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.familyCard"
          :rules="[(v) => !!v || 'Mohon isi nomor KK']"
          label="Nomor KK"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.workExperience"
          :rules="[(v) => !!v || 'Mohon isi pengalaman kerja']"
          label="Pengalaman Kerja"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import store from "../../store/index.js";

export default {
  props: {
    formBorrowerMiddleware: Object,
  },
  data: () => ({
    valid: true,
    birthDateMenu: false,
    startDateMenu: false,
    mapOptions: [
      {
        label: "Pribadi",
        value: "PERSONAL_BORROWER",
      },
      {
        label: "Perusahaan",
        value: "CORPORATE_BORROWER",
      },
    ],
  }),

  computed: {
    formData() {
      return this.formBorrowerMiddleware;
    },
  },
};
</script>
