<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Input Borrower (Middleware)'" />

    <div>
      <h4 class="pb-4">Form Borrower</h4>
      <form-borrower-middleware :formBorrowerMiddleware="formNewBorrower" />
      <button-form :submit="submitBorrower" :loading="loading" class="pt-8" />
    </div>

    <div>
      <h4 class="pt-8 pb-4">Foto</h4>
      <v-card>
        <div class="d-flex">
          <v-img
            :src="'data:image/png;base64,' + formNewBorrower.fotoKtp"
            max-height="200"
          />
          <v-img
            :src="'data:image/png;base64,' + formNewBorrower.fotoSelfie"
            max-height="200"
          />
        </div>
      </v-card>
    </div>

    <div>
      <h4 class="pt-8 pb-4">Data Pinjaman</h4>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Tanggal Kontrak
              </th>
              <th class="text-left">
                Nomor Kontrak
              </th>
              <th class="text-left">
                Jumlah
              </th>
              <th class="text-left">
                Tenor
              </th>
              <th class="text-left">
                Unit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ formNewBorrower.loanData.contractDate }}</td>
              <td>{{ formNewBorrower.loanData.contractNo }}</td>
              <td>
                Rp
                {{ formNewBorrower.loanData.amount }},00
              </td>
              <td>{{ formNewBorrower.loanData.tenor }}</td>
              <td>{{ formNewBorrower.loanData.unit }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div>
      <h4 class="pt-8 pb-4">Referensi</h4>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                NPWP
              </th>
              <th class="text-left">
                Posisi
              </th>
              <th class="text-left">
                Bunga
              </th>
              <th class="text-left">
                Alamat Bisnis
              </th>
              <th class="text-left">
                Status Kepemilikan Bisnis
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ formNewBorrower.references.npwp }}</td>
              <td>{{ formNewBorrower.references.jobPosition }}</td>
              <td>
                {{ formNewBorrower.references.interest }}
              </td>
              <td>
                {{ formNewBorrower.references.businessAddress }},
                {{ formNewBorrower.references.businessVillage }},
                {{ formNewBorrower.references.businessSubdistrict }},
                {{ formNewBorrower.references.businessCity }},
                {{ formNewBorrower.references.businessProvince }}
              </td>
              <td>{{ formNewBorrower.references.businessOwnership }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormBorrowerMiddleware from "../../components/Form/FormBorrowerMiddleware.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    PopupSuccess,
    PopupError,
    ButtonForm,
    FormBorrowerMiddleware,
  },

  data() {
    return {
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      formNewBorrower: {},
    };
  },

  async mounted() {
    this.fetchOneBorrower();
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-borrower-mw");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    async fetchOneBorrower() {
      await store
        .dispatch("starlord/fetchOneBorrowerMiddleware", this.$route.params.id)
        .then((r) => {
          this.formNewBorrower = r;
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    async submitBorrower() {
      this.loading = false;
      try {
        console.log(this.formNewBorrower);
        await store
          .dispatch("starlord/addNewBorrowerMiddleware", this.formNewBorrower)
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log("error", err);
            this.loading = false;
          });
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
  },
};
</script>
